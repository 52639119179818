import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import removeSlots from 'components/ads/removeSlots';
import { ProvideAuth } from 'hooks/auth/useAuth';
import { IsSsrMobileProvider } from 'hooks/isMobile';
import { ModalProvider } from 'hooks/modal';
import { nanoid } from 'nanoid';
import { DM_Sans as DMSans } from 'next/font/google';
import { useRouter } from 'next/router';
import Script from 'next/script';
import 'nprogress/nprogress.css';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/globals.css';
// import initilizeAccesibility from 'utils/accessibility';
import { initializeGTM, logPageView } from 'utils/tracking/gtm';
import { uaCheck } from 'utils/userAgentCheck';

const primary = DMSans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-primary',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_GRAPHCMS_HOST,
});

const growthbook = new GrowthBook({
  enableDevMode: process.env.NODE_ENV !== 'production',
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    initializeGTM();
    logPageView();

    window.googletag = window.googletag || { cmd: [] };
  }, []);

  // disabled AccessiBe plugin
  // useEffect(() => {
  //   initilizeAccesibility();
  // }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', removeSlots);
    return () => {
      router.events.off('routeChangeStart', removeSlots);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nid = nanoid();
    const isHuman = uaCheck();

    let visitorId = localStorage.getItem('visitor_id');
    if (!visitorId) {
      visitorId = isHuman ? nid : 'THIS_IS_A_BOT_USER';
      if (isHuman) {
        localStorage.setItem('visitor_id', visitorId);
      }
    }
    fetch(
      `https://cdn.growthbook.io/api/features/${process.env.REACT_APP_GROWTHBOOK_KEY}`,
    )
      .then((res) => res.json())
      .then((parsed) => {
        growthbook.setFeatures(parsed.features);
        growthbook.setAttributes({
          id: visitorId,
          isHuman,
        });
      });
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <GrowthBookProvider growthbook={growthbook}>
        <ProvideAuth>
          <IsSsrMobileProvider value={pageProps.isSsrMobile}>
            <ModalProvider>
              <ApolloProvider client={client}>
                {process.env.NEXT_PUBLIC_NO_ADS !== 'true' ? (
                  <Script
                    strategy="lazyOnload"
                    src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
                  />
                ) : null}
                <div className={`${primary.variable} font-sans bg-slate-50`}>
                  {/* eslint-disable-next-line */}
                  <Component {...pageProps} />
                </div>
              </ApolloProvider>
              <ToastContainer />
            </ModalProvider>
          </IsSsrMobileProvider>
        </ProvideAuth>
      </GrowthBookProvider>
    </Elements>
  );
}

export default MyApp;
