import TagManager from 'react-gtm-module';
import EVENTS from 'utils/tracking/events';

export const initializeGTM = () => {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID || '',
  };
  TagManager.initialize(tagManagerArgs);
};

export const logPageView = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: EVENTS.PAGEVIEW,
      pagePath: window.location.pathname,
    },
  });
};

export const logEvent = (eventName, eventProps = {}) => {
  const cookieGA = document.cookie
    .split('; ')
    .filter((cookie) => cookie.startsWith('_ga='))[0];
  const cookieGID = document.cookie
    .split('; ')
    .filter((cookie) => cookie.startsWith('_gid='))[0];

  if (eventProps?.user_param?.customer_id) {
    const prefix =
      typeof window === 'undefined' || window.location.host !== 'dwellsy.com'
        ? 'tb'
        : '';

    // eslint-disable-next-line no-param-reassign
    eventProps.user_param.customer_id =
      prefix + eventProps.user_param.customer_id;
  }

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      cookie_ga: cookieGA ? cookieGA.slice(4) : '', // expire in 2 years
      cookie_gid: cookieGID ? cookieGID.slice(5) : '', // expire in 24 hours
      ...eventProps,
    },
  });
};
