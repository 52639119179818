import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useFavorites = create(
  persist(
    (set) => ({
      favorites: [],
      called: [],
      emailed: [],
      shared: [],
      addFavorite: (propertyId) =>
        set((state) => ({ favorites: [...state.favorites, propertyId] })),
      rmFavorite: (propertyId) =>
        set((state) => ({
          favorites: state.favorites.filter((item) => item !== propertyId),
        })),
      syncHistory: (history) => set(() => ({ ...history })),
      addCalled: (propertyId) =>
        set((state) => ({ called: [...state.called, propertyId] })),
      addEmailed: (propertyId) =>
        set((state) => ({ emailed: [...state.emailed, propertyId] })),
      addShared: (propertyId) =>
        set((state) => ({ shared: [...state.shared, propertyId] })),
      clearHistory: () =>
        set({ emailed: [], shared: [], called: [], favorites: [] }),
    }),
    {
      name: 'favorite-storage',
    },
  ),
);

export default useFavorites;
