import { useContext, useMemo, DependencyList } from "react";
import { ModalContext, ModalType, ModalOptionsType } from "./ModalContext";

/**
 * Callback types provided for descriptive type-hints
 */
type ShowModal = () => void;
type HideModal = () => void;

/**
 * Utility function to generate unique number per component instance
 */
const generateModalKey = (() => {
  let count = 0;

  count += 1;
  return () => `${count}`;
})();

const isFunctionalComponent = (Component: Function) => {
  const {prototype} = Component;

  return !prototype || !prototype.isReactComponent;
};

/**
 * React hook for showing modal windows
 */
 // eslint-disable-next-line import/prefer-default-export
 export const useModal = (
  component: ModalType,
  modalOptions: ModalOptionsType = {dismissable: true},
  inputs: DependencyList = []
): [ShowModal, HideModal] => {
  if (!isFunctionalComponent(component)) {
    throw new Error(
      "Only stateless components can be used as an argument to useModal. You have probably passed a class component where a function was expected."
    );
  }

  const key = useMemo(generateModalKey, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modal = useMemo(() => component, [component, ...inputs]);
  const context = useContext(ModalContext);
  context.modalOptions = modalOptions;
  
  const showModal = () => {
    context.showModal(key, modal);
  };
  const hideModal = () => {
    if (modalOptions.dismissable) return null;
    return context.hideModal(key)
  };
  
  return [showModal, hideModal];
};
