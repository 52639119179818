import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock';

import { ModalType } from './ModalContext';

/**
 * Modal Root Props
 */
interface ModalRootProps {
  /**
   * Map of modal instances associated by unique ids
   */
  modals: Record<string, ModalType>;

  /**
   * Container component for modals
   *
   * Modals will be rendered as children of this component. React.Fragment is
   * used by defualt, specifying a different component can change the way modals
   * are rendered across the whole application.
   */
  // eslint-disable-next-line react/require-default-props
  component?: React.ComponentType<any>;

  /**
   * Specifies the root element to render modals into
   */
  // eslint-disable-next-line react/require-default-props
  container?: Element;
  contextValue: any;
}

/**
 * Modal renderer props
 */
interface ModalRendererProps {
  /**
   * Functional component representing the modal
   */
  component: ModalType;
  // eslint-disable-next-line no-unused-vars
  closeModal: (any) => void;
  // eslint-disable-next-line no-unused-vars
  blurModal: (e: any) => void;
}

/**
 * Component responsible for rendering the modal.
 *
 * The identity of `Component` may change depending on the inputs passed to
 * `useModal`. If we simply rendered `<Component />` then the modal would be
 * susceptible to rerenders whenever one of the inputs change.
 */
const ModalRenderer = memo(({ component, ...rest }: ModalRendererProps) =>
  component(rest),
);

/**
 * Modal Root
 *
 * Renders modals using react portal.
 */
function ModalRoot({
  modals,
  container,
  component: RootComponent = React.Fragment,
  contextValue,
}: ModalRootProps) {
  const [mountNode, setMountNode] = useState<Element | undefined>(undefined);

  // This effect will not be ran in the server environment
  useEffect(() => setMountNode(container || document.body), [container]);

  return mountNode
    ? ReactDOM.createPortal(
        <RootComponent>
          {Object.keys(modals).map((key) => (
            <FocusLock>
              <ModalRenderer
                key={key}
                component={modals[key]}
                closeModal={(e) => {
                  contextValue.hideModal(key);
                  if (e) e.stopPropagation();
                  if (contextValue.onClose) contextValue.onClose();
                }}
                blurModal={(e) => {
                  if (contextValue?.modalOptions?.dismissable) {
                    contextValue.hideModal(key, true);
                  }
                  if (e) e.stopPropagation();
                  if (contextValue.onBlur) contextValue.onBlur();
                }}
              />
            </FocusLock>
          ))}
        </RootComponent>,
        mountNode,
      )
    : null;
}

export default ModalRoot;
