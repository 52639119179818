import MobileDetect from 'mobile-detect';
import { createContext, useContext, useEffect, useState } from 'react';

export const getIsSsrMobile = (context) => {
  const md = new MobileDetect(context.req.headers['user-agent']);
  return !!md.mobile();
};
const IsSsrMobileContext = createContext(false);
export const IsSsrMobileProvider = IsSsrMobileContext.Provider;

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useIsMobile = () => {
  const isSsrMobile = useContext(IsSsrMobileContext);
  const { width: windowWidth } = useWindowSize();
  const isBrowserMobile = !!windowWidth && windowWidth < 992;

  return isSsrMobile || isBrowserMobile;
};
