import * as React from 'react';

/**
 * Modals are represented as react components
 *
 * This is what gets passed to useModal as the first argument.
 */
export type ModalType = React.FunctionComponent<any>;

export type ModalOptionsType = {
  // Prevents the modal from being closed by clicking outside of it
  dismissable?: boolean;
};

/**
 * The shape of the modal context
 */
export interface ModalContextType {
  // eslint-disable-next-line no-unused-vars
  showModal(key: string, component: ModalType): any;
  // eslint-disable-next-line no-unused-vars
  hideModal(key: string): any;
  hasOpenModals(): any;
  modalOptions: ModalOptionsType;
}

/**
 * Throw error when ModalContext is used outside of context provider
 */
const invariantViolation = () => {
  throw new Error(
    'Attempted to call useModal outside of modal context. Make sure your app is rendered inside ModalProvider.',
  );
};

/**
 * Modal Context Object
 */
export const ModalContext = React.createContext<ModalContextType>({
  showModal: invariantViolation,
  hideModal: invariantViolation,
  hasOpenModals: invariantViolation,
  modalOptions: { dismissable: true },
});
ModalContext.displayName = 'ModalContext';
