export const persistUser = (result) => {
  localStorage.setItem('authToken', result.session_token);
  localStorage.setItem('user', JSON.stringify(result.user));
};

export const unPersistUser = () => {
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('user');
  localStorage.removeItem('authToken');
};
