export const uaCheck = () => {
  // Returns `true` if the user is a human, `false` if the user is a known bot.
  const { userAgent } = navigator;

  if (userAgent.includes('https://github.com/prerender/prerender'))
    return false;

  if (document.referrer.startsWith('https://gtm-msr.appspot.com/'))
    return false;

  if (userAgent.includes('Googlebot')) return false;

  if (userAgent.includes('Bingbot')) return false;

  if (userAgent.includes('Slurp')) return false;

  if (userAgent.includes('DuckDuckBot')) return false;

  if (userAgent.includes('Baiduspider')) return false;

  if (userAgent.includes('YandexBot')) return false;

  if (userAgent.includes('prerendercloud')) return false;

  if (userAgent.includes('AdsBot-Google')) return false;

  if (userAgent.includes('AhrefsSiteAudit')) return false;

  if (userAgent.includes('Sogou')) return false;

  if (userAgent.includes('ia_archiver')) return false;

  if (userAgent.includes('SemrushBot')) return false;

  if (userAgent.includes('Screaming Frog')) return false;

  return true;
};

export default uaCheck;
