
import { z } from "zod";

const questionListValidator = z.array(z.object({
  id: z.number(),
  order: z.number(),
  title: z.string(),
  short_text: z.string(),
  text: z.string(),
  icon: z.string(),
  answer_type: z.string(),
  placeholder: z.string().nullable(),
  options: z.array(
    z.object({ text: z.string(), alias: z.string(), value: z.string() })
  ).nullable(),
  answer_max: z.any().nullable(),
  answer_min: z.any().nullable(),
  required: z.boolean(),
  user_answer: z.any(),
  user_answer_alias: z.string().nullable()
}));

export const sortProfileQuestions = (questions, sortUnansweredFirst = false, filterOutAnswered = false) => {
  let sorted = questions.sort((a, b) => {
    if (sortUnansweredFirst) {
      // unanswerd questions first
      if (a.user_answer === null && b.user_answer !== null) return -1;
      if (a.user_answer === null && b.user_answer !== null) return 1;
    }
    // then sort by order
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;

    return 0;
  })

  if (filterOutAnswered) {
    sorted = questions.filter(
      (question) =>
        question.user_answer === null ||
        question.user_answer === undefined,
    );
  }

  return sorted;  
}

export const fetchQuestionsFromAPI = async (authToken, url = '/endpoint/questionnaire?tag=profile') => {
  if (!authToken) return [];
  const response = await fetch(`${process.env.REACT_APP_API_BASE}${url}`, {
    headers: {
      'Authentication': authToken || undefined,
      'Content-Type': 'application/json'
    },
    method: 'GET',
  })
  if (response.ok) {
    const rawJson = await response.json()
    const { data: questions, error } = await questionListValidator.safeParseAsync(rawJson);
    if (!error) {
      return questions
    }
  }
  return [];
};

export default fetchQuestionsFromAPI;