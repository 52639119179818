import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';

import { ModalContext, ModalType } from './ModalContext';
import ModalRoot from './ModalRoot';

/**
 * Modal Provider Props
 */
export interface ModalProviderProps {
  /**
   * Specifies the root element to render modals into
   */
  // eslint-disable-next-line react/require-default-props
  container?: Element;

  /**
   * Container component for modal nodes
   */
  // eslint-disable-next-line react/require-default-props
  rootComponent?: React.ComponentType<any>;

  /**
   * Subtree that will receive modal context
   */
  children: React.ReactNode;
}

/**
 * Modal Provider
 *
 * Provides modal context and renders ModalRoot.
 */
export function ModalProvider({
  container,
  rootComponent,
  children,
}: ModalProviderProps) {
  if (container && !(container instanceof HTMLElement)) {
    throw new Error(`Container must specify DOM element to mount modal root into.

    This behavior has changed in 3.0.0. Please use \`rootComponent\` prop instead.
    See: https://github.com/mpontus/react-modal-hook/issues/18`);
  }
  const [modals, setModals] = useState<Record<string, ModalType>>({});
  const showModal = useCallback(
    (key: string, modal: ModalType) =>
      setModals((existingModals) => ({
        ...existingModals,
        [key]: modal,
      })),
    [],
  );
  const hideModal = useCallback(
    (key: string) =>
      setModals((existingModals) => {
        if (!existingModals[key]) {
          return existingModals;
        }
        const newModals = { ...existingModals };
        delete newModals[key];
        return newModals;
      }),
    [],
  );
  const hasOpenModals = useCallback(() => Object.keys(modals).length > 0, []);
  const contextValue = useMemo(
    () => ({ showModal, hideModal, hasOpenModals, modalOptions: {} }),
    [hideModal, showModal, hasOpenModals],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <>
        {children}
        <ModalRoot
          modals={modals}
          component={rootComponent}
          container={container}
          contextValue={contextValue}
        />
      </>
    </ModalContext.Provider>
  );
}
