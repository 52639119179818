// READ THIS BEFORE CREATING A NEW EVENT
// Events cannot contain hyphens and may ONLY contain letters,
// numbers, and underscores.
// READ THIS BEFORE CREATING A NEW EVENT

export const EVENTS = {
  PAGEVIEW: 'pageview',
  CLICK: 'click',
  SIGNUP: 'sign_up',
  LOGGED_IN: 'logged_in',
  ONETAP_LOGIN: 'logged_in_with_google_one_tap',
  IDENTIFY: 'identify',
  AUTH: 'auth',

  // Custom
  PROPERTY_VIEW: 'propertyview',
  CONTINUE_READING: 'continue_reading_description',
  // Auth
  REGISTERED: 'registration',
  VERIFY: 'user_verified',
  LOGIN: 'login',
  LOGGED_OUT: 'logged_out',
  LOGIN_CANCELED: 'login_canceled',
  UPDATED_PROFILE: 'updated_profile',

  // Contact
  MESSAGE_SENT: 'message_sent',

  // Search
  SEARCH: 'search',
  FILTER_SEARCH: 'filtered_search',
  FILTER_PRICE: 'filtered_search_by_price',
  FILTER_BEDS: 'filtered_search_by_bedrooms',
  FILTER_BATHS: 'filtered_search_by_bathrooms',
  PAGINATE: 'paginated',
  TOGGLE_VERIFIED: 'filtered_search_by_verified',
  ADD_AMENITIES: 'filtered_search_by_amenity_filter',
  // New Search
  MAP_MOVE: 'map_move',
  MAP_ZOOM: 'map_zoom',
  ADDITIONAL_SEARCH_FILTERS: 'additional_search_filters',
  MOBILE_SEARCH_FILTERS: 'mobile_search_filters',
  BED_BATH_EXACT: 'specified_exact_bedrooms',
  TOGGLE_WANT_NEED: 'toggled_wants_vs_needs',
  RESET_FILTERS: 'reset_filters_click',
  PREV_NEXT_PROP: 'click_prev_or_next',

  // Favorites
  ADD_FAVORITE: 'favorite',
  DEL_FAVORITE: 'del_favorite',

  // Contacts
  CONTACT_STARTED: 'contact_property_started',
  CONTACTED_PROPERTY: 'contacted_property_manager',

  // Inquiry
  INQUIRY_CALL: 'inquiry_call',
  INQUIRY_EMAIL: 'inquiry_email',
  SHARE: 'share',
  SHARE_INITIATE: 'share_initiate',
  DWELLSY_SCORE: 'see_dwellsy_score',
  EMAIL_AFTER_CALL: 'compose_email_after_call',

  // MediaViewer
  PROPERTY_PICTURES: 'see_property_pictures',
  PROPERTY_VIDEOS: 'see_property_videos',
  VIRTUAL_TOUR: 'see_property_virtual_tours',
  MAP_VIEW: 'see_property_map_view',
  STREET_VIEW: 'see_property_street_view',
  PROPERTY_FLOORPLANS: 'see_property_floorplans',

  // SMS OPT IN
  SMS_OPT_IN: 'sms_opt_in',

  // ITA Workflow
  ITA_PAGEVIEW: 'ita_pageview',
  ITA_START: 'ita_start',
  ITA_CLICK_BUTTON: 'ita_click_button',
  ITA_EMAIL_VERIFIED: 'ita_email_verified',
  ITA_SMS_OPT_IN: 'ita_sms_opt_in',
  ITA_PHONE_VERIFIED: 'ita_phone_verified',
  ITA_PAYMENT_SUCCESS: 'ita_payment_success',
  ITA_UPDATE_PAYMENT_SUCCESS: 'ita_update_payment_success',
  ITA_FREE_PLAN_SUCCESS: 'ita_free_plan_success',
  ITA_FILTER_SET: 'ita_filter_set',
  ITA_MAX_ALERT_SET: 'ita_max_alert_set',
  ITA_FINISH: 'ita_fininsh',
  ITA_PAUSE_ALERT: 'ita_pause_alert',
  ITA_CANCEL: 'ita_cancel',
  ITA_CONTINUE_AFTER_SEEING_PRICE_CLICK: 'ita_continue_after_seeing_price',

  // Carousel
  SWIPE_CAROUSEL: 'swipe_carousel_image',

  // Lemonade
  VISIT_LEMONADE: 'visit_lemonade',

  // Updater Promo
  UPDATER_PROMO_FORM_VIEW: 'updater_promo_form_view',
  UPDATER_PROMO_FORM_SUBMISSION: 'updater_promo_form_submission',
  UPDATER_PROMO_QUESTION_ANSWERED: 'updater_promo_question_answered',

  // Sign Up events
  SIGN_UP_INITIATE: 'sign_up_initiate',
  SIGN_UP_CONTINUE: 'sign_up_continue',
  SIGN_UP_SUCCESS: 'sign_up_success',
  SIGN_UP_FOR_DWELLSY_EDGE: 'sign_up_for_dwellsy_edge',

  SEARCH_LISTINGS: 'search_listings',
};

export default EVENTS;
