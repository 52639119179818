import { useFeature } from '@growthbook/growthbook-react';
import { deleteCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { fetchQuestionsFromAPI } from 'queries/fetchProfileQuestions';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFavorites } from 'store/store';
import { EVENTS } from 'utils/tracking/events';
import { logEvent } from 'utils/tracking/gtm';

import useScript from '../useScript';
import {
  getOAuthUrl,
  getUserFromAPI,
  getUserHistory,
  handleOneTapCredentialResponse,
  resendSMSVerification,
  sendContactInfoToAPI,
  sendEmailToAPI,
  sendInquiryToProperty,
  sendPhoneVerificationToAPI,
  sendUserFlagsToAPI,
  sendVerificationToAPI,
  verifyOAuthUrl,
} from './useAuthNetwork';
import { persistUser, unPersistUser } from './useAuthSupport';
import { SIGNIN_SIGNUP } from './userSource';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

const initialOAuth = { started: false, type: null };
const oneTapScriptFlag = '__googleOneTapScript__';
const googleClientScriptURL = 'https://accounts.google.com/gsi/client';

function useProvideAuth() {
  const router = useRouter();
  const syncHistoryStore = useFavorites((state) => state.syncHistory);

  const syncUserHistory = (authToken) => {
    getUserHistory(authToken).then((history) => {
      syncHistoryStore({
        favorites: history
          ?.filter((item) => item.favorite)
          .map((item) => item.property_id),
        called: history
          ?.filter((item) => item.called)
          .map((item) => item.property_id),
        emailed: history
          ?.filter((item) => item.emailed)
          .map((item) => item.property_id),
        shared: history
          ?.filter((item) => item.shared)
          .map((item) => item.property_id),
      });
    });
  };

  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user')) ?? null;
    const authToken = window.localStorage.getItem('authToken') ?? null;
    const oAuth =
      JSON.parse(window.localStorage.getItem('oAuth')) ?? initialOAuth;
    setUser(user);
    setAuthToken(authToken);
    setOAuth(oAuth);
    setInitialized(true);
    if (authToken) {
      setCookie('DwellsyId', authToken);
      getUserFromAPI(authToken).then((result) => {
        if (result?.email?.length > 3) {
          setUser(result);
          syncUserHistory(authToken);
        } else if (result.code === 401) {
          signOut();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [verifyToken, setVerifyToken] = useState(false);
  const [oAuth, setOAuth] = useState(initialOAuth);

  const verify = (verifyCode, userVerifyToken, isEmailCode = true) => {
    const data = {
      verify_code: verifyCode,
      type: isEmailCode ? 'email' : 'phone',
      token: userVerifyToken,
    };
    return sendVerificationToAPI(data).then((result) => {
      if (!result) {
        throw new Error('Network Error');
      }
      if (result?.code === 200) {
        signInUser(result);
        syncUserHistory(result.session_token);
      } else {
        throw new Error(result.message || 'Unknown error');
      }
    });
  };

  const verifyPhone = (verifyCode) => {
    const data = { code: verifyCode };
    return sendPhoneVerificationToAPI(authToken, data).then((result) => {
      if (!result) {
        throw new Error('Network Error');
      }
      if (result?.code === 200) {
        setUser(result.user);
      }

      return result;
    });
  };

  const submitEmail = (email, source) => {
    const data = {
      email,
      source: source || SIGNIN_SIGNUP,
    };
    return sendEmailToAPI(data).then((result) => {
      if (!result) {
        throw new Error('Network Error');
      }
      if (result.code === 200) {
        setVerifyToken(result.token);
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.removeItem('authToken');
        setUser(data);
        setAuthToken(null);
      } else {
        throw new Error(result.message || 'Unknown error');
      }
    });
  };

  const submitContactInfoUpdate = (rawContactInfo) => {
    const contactInfo = rawContactInfo;
    // TODO: convert to using the typescript ContactInfo type from import {ContactDetails} from 'components/account/ContactInfo/ContactInfo';
    // import { z } from 'zod';
    // import {contactDetailsValidator} from 'components/account/ContactInfo/ContactInfo';
    // const safeContactInfo = contactDetailsValidator.parse(contactInfo); // errors out if invalid data
    sendContactInfoToAPI(authToken, contactInfo).then((result) => {
      if (result.code === 200) {
        setVerifyToken(result.token);
        localStorage.setItem('user', JSON.stringify(result.user));
        setUser(result.user);
      } else if (result.code === 401) {
        signOut();
      }
    });
  };

  const forceRefetchUser = async () => {
    const result = await getUserFromAPI(authToken);
    // console.log('forceRefetchUser user', result);
    setUser(result);
  };

  const updateUserFlags = async (lowPrice, noTours, moneyFirst) =>
    sendUserFlagsToAPI(authToken, lowPrice, noTours, moneyFirst);
  const sendInquiry = async (message, propertyId) =>
    sendInquiryToProperty(authToken, user, message, propertyId);
  const fetchQuestions = async (url) => fetchQuestionsFromAPI(authToken, url);

  const signInUser = (data) => {
    persistUser(data);
    setUser(data.user);
    setAuthToken(data.session_token);
    setCookie('DwellsyId', data.session_token);
    if (data?.user?.login_count === 1) {
      logEvent(EVENTS.SIGNUP, {
        user_param: {
          customer_id: data.user.id,
          source: data.user.user_source,
          email: data.user.email,
        },
      });
    }
    logEvent(EVENTS.IDENTIFY, {
      user_param: {
        customer_id: data.user.id,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        email: data.user.email,
        phone: data.user.phone ? `+1${data.user.phone}` : null,
        source: data.user.user_source,
        phone_verified: data.user.phone_verified,
        email_verified: data.user.email_verified,
        offset: data.user.offset,
        join_at: data.user.creation_time,
        login_count: data.user.login_count,
        sms_is_subscribed: data.user.sms_is_subscribed,
        unsubscribed_sms: !data.user.sms_is_subscribed,
      },
    });
  };

  const resetEmail = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  const resendSMSCode = async () => resendSMSVerification(authToken);

  const signOut = () => {
    deleteCookie('DwellsyId');
    unPersistUser();
    setUser(null);
    setAuthToken(null);
    setVerifyToken(null);
  };

  const goToLoginPage = () => {
    router.push('/login');
  };

  const forceAuth = () => {
    // client-side only
    if (typeof window !== 'undefined' && initialized && !authToken) {
      goToLoginPage();
    }
  };

  /* *******************  ↓ ↓ ↓ ↓   oAuth   ↓ ↓ ↓ ↓  ************************* */

  const setOAuthState = (data) => {
    setOAuth((oldOAuth) => {
      const newOAuth = { ...oldOAuth, ...data };
      localStorage.setItem('oAuth', JSON.stringify(newOAuth));

      return newOAuth;
    });
  };

  const clearOauthState = () => {
    localStorage.removeItem('oAuth');
    setOAuth(initialOAuth);
  };

  useEffect(() => {
    if (oAuth && oAuth.started) {
      if (oAuth.code || oAuth.token) {
        verifyOAuthUrl(oAuth).then((data) => {
          if (data?.user) {
            signInUser(data);
            syncUserHistory(data.session_token);
            router.push(oAuth.page);
          }

          clearOauthState();
        });
      } else {
        // stop oAuth if it's not complete in 8 seconds
        setTimeout(clearOauthState, 8000);
      }
    }
    // eslint-disable-next-line
  }, [oAuth]);

  const handleOAuth = (type, source) => {
    if (oAuth.started) {
      return;
    }
    logEvent(EVENTS.SIGN_UP_CONTINUE, {
      buttonSource: source,
      authType: type,
    });
    getOAuthUrl(type).then((data) => {
      if (data?.auth_url) {
        setOAuthState({
          type,
          started: source,
          page: window.location.pathname + window.location.search,
        });

        window.location = data.auth_url;
      } else {
        clearOauthState();
      }
    });
  };

  /* *******************  ↑ ↑ ↑ ↑   oAuth   ↑ ↑ ↑ ↑  ************************* */
  /* ***************  ↓ ↓ ↓ ↓   Google OneTap   ↓ ↓ ↓ ↓  ********************* */

  const script = useScript(googleClientScriptURL);
  const socialLoginsEnabled = useFeature('socialloginsenabled').value;

  const disabled =
    authToken || (socialLoginsEnabled && !socialLoginsEnabled.googleOneTap);

  useEffect(() => {
    if (!window?.[oneTapScriptFlag] && script === 'ready') {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        callback: (data) => {
          handleOneTapCredentialResponse({
            data,
            onSuccess: (response) => {
              if (response?.user) {
                signInUser(response);
                syncUserHistory(response.session_token);
              }
            },
            url: window.location.pathname,
          });

          logEvent(EVENTS.SIGN_UP_CONTINUE, {
            authType: 'google_one_tap',
          });
        },
      });
      window[oneTapScriptFlag] = true;
    }

    if (window?.[oneTapScriptFlag] && script === 'ready' && !disabled) {
      window.google.accounts.id.prompt(() => {
        // console.log(notification);
      });
    }
    // eslint-disable-next-line
  }, [script, disabled]);

  /* ***************  ↑ ↑ ↑ ↑   Google OneTap   ↑ ↑ ↑ ↑  ********************* */

  return {
    authToken,
    user,
    loggedIn: !!authToken,
    verifyToken,
    oAuth,
    setOAuthState,
    resetEmail,
    forceAuth,
    signOut,
    goToLoginPage,
    submitEmail,
    verify,
    verifyPhone,
    handleOAuth,
    _clearOauthState: clearOauthState,
    resendSMSCode,
    submitContactInfoUpdate,
    forceRefetchUser,
    updateUserFlags,
    sendInquiry,
    fetchQuestions,
  };
}
