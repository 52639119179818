export const sendVerificationToAPI = async (input) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/verifyAccount`;
  return fetch(url, { method: 'POST', body: JSON.stringify(input) })
    .then((response) => {
      if (response.status === 500) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => response)
    .catch(() => {
      throw new Error('Network Error');
    });
};

export const sendPhoneVerificationToAPI = async (authToken, data) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/user/phone/verify`;
  return fetch(url, {
    headers: {
      Authentication: authToken || undefined,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.status === 500) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => response)
    .catch(() => {
      throw new Error('Network Error');
    });
};

export const sendEmailToAPI = async (input) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/user`;
  return fetch(url, { method: 'POST', body: JSON.stringify(input) })
    .then((response) => {
      if (response.status === 500) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((response) => response)
    .catch(() => {
      throw new Error('Network Error');
    });
};

export const sendContactInfoToAPI = async (authToken, input) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/user`;
  return fetch(url, {
    headers: {
      Authentication: authToken || undefined,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(input),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch(() => {});
};

export const sendUserFlagsToAPI = async (
  authToken,
  lowPrice,
  noTours,
  moneyFirst,
) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/user/flags`;
  return fetch(url, {
    headers: {
      Authentication: authToken || undefined,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      low_price: lowPrice,
      no_tours: noTours,
      money_first: moneyFirst,
    }),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch(() => {});
};

export const sendInquiryToProperty = async (
  authToken,
  user,
  message,
  propertyId,
) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/property/${propertyId}/contact`;
  return fetch(url, {
    headers: {
      Authentication: authToken || undefined,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      message,
      name: `${user?.first_name || ''} ${user?.last_name || ''}`,
      email: user?.email,
      phone: user?.phone,
    }),
  })
    .then((response) => response.json())
    .then((response) => response)
    .catch(() => {});
};

export const getUserHistory = async (authToken) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/history`;
  if (!authToken) return null;
  const headers = {
    Authentication: authToken,
  };

  return fetch(url, { method: 'GET', headers })
    .then((response) => response.json())
    .then((response) => response);
};

export const getUserFromAPI = async (authToken) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/user`;
  if (!authToken) return null;
  const headers = {
    Authentication: authToken,
  };

  return fetch(url, { method: 'GET', headers })
    .then((response) => response.json())
    .then((response) => response);
};

const PROD = 'dwellsy.com';
const DEVELOP = 'develop.dwellsy.com';

export const getOAuthUrl = async (type) => {
  let url = `${process.env.REACT_APP_API_BASE}/endpoint/oauth/${type}`;
  if (
    window.location.hostname !== PROD &&
    window.location.hostname !== DEVELOP
  ) {
    url += `?redirect=https://${window.location.host}`;
  }

  return fetch(url)
    .then((response) => response.json())
    .catch((error) => {
      if (
        !error.request ||
        error.request.status === 500 ||
        !error.response?.data?.message ||
        error.response.data.message === 'Bad request.'
      ) {
        // eslint-disable-next-line no-alert
        alert(
          'Server Error! Cannot perform this action now. Please try again later.',
        );
      } else {
        // eslint-disable-next-line no-alert
        alert(error.response.data.message);
      }
    });
};

export const verifyOAuthUrl = async (oAuth) => {
  if (!oAuth) {
    return null;
  }
  const verifyApiUrl = `${process.env.REACT_APP_API_BASE}/endpoint/verify/${oAuth.type}`;
  const data = {
    state: oAuth.state,
    code: oAuth.code,
    token: oAuth.token,
    page: oAuth.page,
    source: oAuth.started,
  };
  if (
    window.location.hostname !== PROD &&
    window.location.hostname !== DEVELOP
  ) {
    data.redirect = `https://${window.location.host}`;
  }

  return fetch(verifyApiUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((error) => {
      if (
        !error.request ||
        error.request.status === 500 ||
        !error.response?.data?.message ||
        error.response.data.message === 'Bad request.'
      ) {
        // eslint-disable-next-line no-alert
        alert(
          'Server Error! Cannot perform this action now. Please try again later.',
        );
      } else {
        // eslint-disable-next-line no-alert
        alert(error.response.data.message);
      }
    });
};

export const handleOneTapCredentialResponse = ({ data, url, onSuccess }) => {
  if (data.credential) {
    const oAuthEndpointURL =
      'https://oauth2.googleapis.com/tokeninfo?id_token=';
    fetch(`${oAuthEndpointURL}${data.credential}`)
      .then((resp) => {
        if (resp?.status === 200 && resp?.json) {
          return resp.json();
        }

        throw new Error('Something went wrong');
      })
      .then((json) => {
        const verifyApiUrl = `${process.env.REACT_APP_API_BASE}/endpoint/verify/GoogleOneTap`;
        const requestBody = {
          jwt: data.credential,
          email: json.email,
          url,
        };

        return fetch(verifyApiUrl, {
          method: 'POST',
          body: JSON.stringify(requestBody),
        })
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp?.code !== 200) {
              throw new Error('invalid credentials');
            }
            if (onSuccess) onSuccess(resp);
          });
      })
      .catch(() => {});
  }
};

export const resendSMSVerification = async (authToken) => {
  const url = `${process.env.REACT_APP_API_BASE}/endpoint/user/phone/resend`;
  if (!authToken) return null;
  const headers = {
    Authentication: authToken,
  };

  const response = await fetch(url, { method: 'POST', headers });
  const json = await response.json();
  return json.code === 200;
};
